/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images
import awsCCP from "../../../assets/images/credly/awsCCP.png";
import awsCloudQuestCCP from "../../../assets/images/credly/awsCloudQuestCCP.png";
import awsWellArchitected from "../../../assets/images/credly/well-architected.png";
import awsCloud101 from "../../../assets/images/credly/aws-cloud101.png";
import awsPartnerTechnical from "../../../assets/images/credly/aws-partner-accreditation-technical.png";
import awsPartnerEconomics from "../../../assets/images/credly/aws-partner-cloud-economics-accreditation.png";
import awsPartnerBusiness from "../../../assets/images/credly/aws-partner-sales-accreditation-business.png";
import awsPartnerAI from "../../../assets/images/credly/aws-partner-generative-ai-essentials-business.png";
import awsKnowledgeCloudEssentials from "../../../assets/images/credly/aws-knowledge-cloud-essentials.png";
import awsKnowledgeArchitecting from "../../../assets/images/credly/aws-knowledge-architecting.png";
import awsKnowledgeDataProtection from "../../../assets/images/credly/aws-knowledge-data-protection-disaster-recovery.png";
import awsKnowledgeServerless from "../../../assets/images/credly/aws-knowledge-serverless.png";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2">Professional development</MKTypography>
          <MKTypography variant="h2" color="info" textGradient mb={2}>
            <a
              href="https://www.credly.com/users/tudor-andrei-helesteanu/badges"
              target="_blank"
              rel="noreferrer"
            >
              &nbsp; 15+ Technical Certifications
            </a>
          </MKTypography>
        </Grid>

        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={awsCCP} alt="Apple" width="100%" opacity={0.9} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={awsCloudQuestCCP}
              alt="Facebook"
              width="100%"
              opacity={0.9}
            />
          </Grid>
          {/* <Grid item xs={6} md={4} lg={2}> */}
          {/*  <MKBox component="img" src={designThinking} alt="Nasa" width="100%" opacity={0.9} /> */}
          {/* </Grid> */}
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={awsWellArchitected}
              alt="Vodafone"
              width="100%"
              opacity={0.9}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={awsKnowledgeServerless}
              alt="DigitalOcean"
              width="100%"
              opacity={0.9}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={awsKnowledgeCloudEssentials}
              alt="awsKnowledgeCloudEssentials"
              width="100%"
              opacity={0.9}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={awsKnowledgeArchitecting}
              alt="architecting"
              width="100%"
              opacity={0.9}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={awsPartnerBusiness}
              alt="awsPartnerBusiness"
              width="100%"
              opacity={0.9}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={awsPartnerTechnical}
              alt="awsPartnerTechnical"
              width="100%"
              opacity={0.9}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={awsPartnerEconomics}
              alt="awsPartnerEconomics"
              width="100%"
              opacity={0.9}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={awsPartnerAI}
              alt="awsPartnerAI"
              width="100%"
              opacity={0.9}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={awsCloud101}
              alt="DigitalOcean"
              width="100%"
              opacity={0.9}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={awsKnowledgeDataProtection}
              alt="DigitalOcean"
              width="100%"
              opacity={0.9}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2">Trusted by over</MKTypography>
          <MKTypography variant="h2" color="info" textGradient mb={2}>
            &nbsp; 20+ clients
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            I have worked for multiple Fortune 500 companies, startups, and governmental
            institutions
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Nick Willever"
              date="1 year ago"
              review="As a Senior Java Engineer, Tudor has demonstrated exceptional skill in leveraging AWS services to enhance our applications. His knowledge of Spring Boot, combined with his practical use of AWS Beanstalk, SQS, SNS, and DynamoDB, has allowed us to build robust, high-performance solutions."
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="info"
              name="Sally W. Calvert"
              date="1 year ago"
              review="Tudor is a standout Tech Lead, with profound knowledge in Spring Boot and Java. His architectural designs using AWS and Kubernetes are innovative. His adeptness with Kafka and RabbitMQ for building event-driven systems, along with his strategic use of NoSQL databases, has elevated our team's capabilities."
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="David Figueroa"
              date="5 years ago"
              review="Tudor's technical acumen in Java and Spring Boot is unmatched. His designs using AWS Cloud Native Architecture are both innovative and practical. His adept handling of K8s and Kafka has been central to our success in building robust, distributed systems."
              rating={5}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
      </Container>
    </MKBox>
  );
}

export default Information;
