// eslint-disable-next-line import/no-unresolved
import Grid from "@mui/material/Grid";
import React from "react";
// eslint-disable-next-line import/no-unresolved
import useForm from "./useForm";
import MKInput from "../../../components/MKInput";
import MKButton from "../../../components/MKButton";
import MKBox from "../../../components/MKBox";

const FORM_ENDPOINT = "https://e38g3v7ad5.execute-api.us-east-1.amazonaws.com/message";

const Form = () => {
  // const formElement = useRef(null);
  const additionalData = {
    date: new Date().toISOString(),
    id: Math.floor(Math.random() * 500000).toString(),
  };

  const { handleSubmit, status, message } = useForm({
    additionalData,
  });

  if (status === "success") {
    return (
      <>
        <div>Thank you!</div>
        <div>{message}</div>
      </>
    );
  }

  if (status === "error") {
    return (
      <>
        <div>Something bad happened!</div>
        <div>{message}</div>
      </>
    );
  }

  return (
    <MKBox
      width="100%"
      component="form"
      autoComplete="off"
      form
      action={FORM_ENDPOINT}
      onSubmit={handleSubmit}
      method="PUT"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <MKInput
            variant="standard"
            label="Full Name"
            InputLabelProps={{ shrink: true }}
            fullWidth
            name="name"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MKInput
            type="email"
            variant="standard"
            label="Email"
            InputLabelProps={{ shrink: true }}
            fullWidth
            name="email"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <MKInput
            variant="standard"
            label="What can we help you?"
            placeholder="Describe your problem in at least 250 characters"
            InputLabelProps={{ shrink: true }}
            multiline
            fullWidth
            rows={6}
            name="message"
            required
          />
        </Grid>
      </Grid>
      {status !== "loading" && (
        <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
          <MKButton type="submit" variant="gradient" color="info">
            Send Message
          </MKButton>
        </Grid>
      )}
    </MKBox>
  );
};

export default Form;
