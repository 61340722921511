// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/present/logo.jpg";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Helesteanu Tudor",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/tudor.helesteanu",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/thelesteanu",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/thelesteanu",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/@the_hele",
    },
  ],
  menus: [
    {
      name: "clients",
      items: [
        { name: "WCO", href: "https://www.wcoomd.org" },
        { name: "Camversity", href: "https://www.camversity.com" },
        { name: "Foodpanda", href: "https://www.foodpanda.com" },
        { name: "Personio", href: "https://www.personio.com" },
      ],
    },
    {
      name: "resources",
      items: [
        { name: "LinkedIn", href: "https://www.linkedin.com/in/thelesteanu/" },
        { name: "Github", href: "https://github.com/thelesteanu" },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "contact us", href: "https://helesteanu.com/contact-us" },
        { name: "about us", href: "https://helesteanu.com/about-us" },
      ],
    },
    {
      name: "certifications",
      items: [
        { name: "Credly", href: "https://www.credly.com/users/tudor-andrei-helesteanu/badges" },
        {
          name: "AWS Certified Cloud Practitioner",
          href: "https://cp.certmetrics.com/amazon/en/public/verify/credential/W9EWSNEC9144QXWS",
        },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Website by{" "}
      <MKTypography
        component="a"
        href="https://helesteanu.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Tudor Helesteanu
      </MKTypography>
      .
    </MKTypography>
  ),
};
