/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function Counters() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={25000}
              suffix="+"
              title="Development Hours"
              description="I've led the design and development of robust, high-performance Java applications. My journey has been marked by deep dives into Spring Boot, microservices, and cloud technologies, ensuring scalable and resilient systems."
            />
          </Grid>
          <Grid item xs={12} md={3} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <DefaultCounterCard
              count={30}
              suffix="+"
              title="Projects"
              description="I've woven a tapestry of code that powers diverse applications, each project sharpening my skills and deepening my expertise."
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={20}
              title="Technologies Mastered"
              description="My expertise extends to orchestrating containerised applications with Kubernetes, and designing distributed applications that communicate seamlessly via GraphQL."
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={13}
              title="Years in Web Development"
              description="With a robust 13-year background in Java development, I specialise in Spring Framework, Spring Boot, and building resilient cloud-native architectures on AWS. "
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
